<template>
  <Subheader />
  <div ref="sto-banner" class="sto-banner">
    <div class="container h-100">
      <div v-if="activeSto?.display_status" class="row h-100">
        <div v-if="!countDown?.days.toString().length">
          <div class="loader"></div>
        </div>
        <div
          v-else
          class="
            col-12 col-lg-6
            h-100
            d-flex
            justify-content-center
            flex-column
          "
        >
          <h2 class="mb-0">{{$t('BLSO_FULL')}}</h2>
          <p class="sto-banner-text">
            {{$t('BLSO_BANNER_TEXT')}}
          </p>
          <p 
            class="sto-status-text"
            v-if="activeSto?.status === 7"
          >{{$t('BLSO_ENDED')}}</p>
          <p 
            class="sto-status-text"
            v-if="activeSto?.status === 6 || activeSto?.status === 4 || activeSto?.status === 5"
          >{{$t('BLSO_PROCESSING')}}</p>
          <p
            class="sto-status-text"
            v-else-if="activeSto?.status === 2"
          >
            {{$t('BLSO_IN_PROGRESS')}}
          </p>
          <p
            class="sto-status-text"
            v-else-if="activeSto?.status === 1"
          >
            {{$t('BLSO_STARTING_SOON')}}
          </p>
          <p
            class="sto-status-text"
            v-else-if="activeSto?.status === 3"
          >
            {{$t('BLSO_EXPIRED')}}
          </p>
          <div
            v-if="stoStatus && countDown.days != undefined"
            class="countdown-wrapper"
          >
            <div class="countdown-unit-wrapper countdown-days">
              <div class="countdown-num-wrapper">
                <span class="digit">{{ twoDigits(countDown.days) }}</span>
              </div>
              <p class="mt-3">{{$t('DAYS')}}</p>
            </div>
            <span class="countdown-divider">:</span>
            <div class="countdown-unit-wrapper countdown-hours">
              <div class="countdown-num-wrapper">
                <span class="digit">{{ twoDigits(countDown.hours) }}</span>
              </div>
              <p class="mt-3">{{$t('HOURS')}}</p>
            </div>
            <span class="countdown-divider">:</span>
            <div class="countdown-unit-wrapper countdown-minutes">
              <div class="countdown-num-wrapper">
                <span class="digit">{{ twoDigits(countDown.minutes) }}</span>
              </div>
              <p class="mt-3">{{$t('MINUTES')}}</p>
            </div>
            <span class="countdown-divider">:</span>
            <div class="countdown-unit-wrapper countdown-seconds">
              <div class="countdown-num-wrapper">
                <span class="digit">{{ twoDigits(countDown.seconds) }}</span>
              </div>
              <p class="mt-3">{{$t('SECONDS')}}</p>
            </div>
          </div>
          <div
            v-if="activeSto.status == 2"
            class="progress-wrapper"
          >
            <div
              :style="`width: ${(Number(activeSto.display_current_volume) / Number(activeSto.display_volume)) * 100}%`"
              class="progress-bar"
            ></div>
          </div>
          <div v-if="activeSto.status === 2" class="d-flex sto-progress-info justify-content-between">
            <span>{{activeSto.display_current_volume}} EUR</span>
            <span>{{((activeSto.display_current_volume / activeSto.display_volume) * 100).toFixed(2) }}%</span>
            <span>{{activeSto.display_volume}} EUR</span>
          </div>
          <div class="d-flex banner-buttons">
            <router-link to="/investments" class="button me-3">{{
              Number(activeSto.display_current_volume) >=
              Number(activeSto.display_volume)
                ? $t('VIEW_INVESTMENT')
                : $t('INVEST_NOW')
            }}</router-link>
            <button @click="handleShowInvestInfoModal" class="button white" to="/"
              >{{$t('HOW_TO_INVEST')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading">
    <div class="loader"></div>
  </div>
  <div v-if="noSto">
    <h2 class="text-center">{{$t('NO_ACTIVE_STO')}}</h2>
  </div>
  <div v-if="!noSto">
    <div class="container">
      <div v-if="activeSto.display_status" class="row gx-0 gx-md-4 sto-cards-row">
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>{{$t('BLSO_STATUS')}}</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="statusIcon" alt="" />
              <span class="sto-status-text"
                >{{
                  activeSto.status === 2
                    ? $t('IN_PROGRESS')
                    : activeSto.display_status
                }}
                <small v-if="activeSto.status === 2"
                  >{{
                    (
                      (activeSto.display_current_volume /
                        activeSto.display_volume) *
                      100
                    ).toFixed(2)
                  }}%</small
                ></span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
              <div class="sto-card">
                  <h3>{{$t('MAX_CAP')}}</h3>
                  <div class="sto-card-info d-flex">
                      <img class="sto-card-icon me-3" :src="plusIcon" alt="">
                      <span class="sto-status-text">{{activeSto.display_volume}} <small>EUR</small> </span>
                  </div>
              </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4">
              <div class="sto-card">
                  <h3>{{$t('ACCEPTED_CURRENCIES')}}</h3>
                  <div class="sto-card-info d-flex">
                      <img class="sto-card-icon me-3" :src="paymentIcon" alt="">
                      <span class="sto-status-text">BTC & ETH</span>
                  </div>
              </div>
          </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>{{$t('PRICE_PER_SHARE')}}</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="eurIcon" alt="" />
              <span class="sto-status-text"
                >{{ (activeSto.token_price / 100).toFixed(2) }}
                <small>EUR</small>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>{{$t('START_DATE')}}</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="startIcon" alt="" />
              <span class="sto-status-text"
                >{{ activeSto.start_date.slice(0, 10) }}
                <small>{{ activeSto.start_date.slice(11, 19) }}</small></span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>{{$t('END_DATE')}}</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="endIcon" alt="" />
              <span class="sto-status-text"
                >{{ activeSto.end_date.slice(0, 10) }}
                <small>{{ activeSto.end_date.slice(11, 19) }}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    @click="closeModal"
    :class="showInvestInfoModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <div
      @click.stop
      :class="showInvestInfoModal && 'active'"
      class="modal position-relative"
    >
      <div @click="closeModal" class="close-modal">✕</div>
      <div class="modal-content">
        <h4 class="text-center mb-4 mt-3">{{$t('HOW_TO_INVEST_4_STEPS')}}</h4>
        <div class="invest-steps">
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="oneIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">{{$t('HOW_TO_INVEST_STEP_1')}}</p>
          </div>
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="twoIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">{{$t('HOW_TO_INVEST_STEP_2')}}</p>
          </div>
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="threeIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">{{$t('HOW_TO_INVEST_STEP_3')}}</p>
          </div>
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="fourIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">{{$t('HOW_TO_INVEST_STEP_4')}}</p>
          </div>
        </div>
        <div class="disclaimer-box">
        <h5 class="disclaimer-title">{{$t('DISCLAIMER')}}</h5>
          <p>
            {{$t('INVEST_DISCLAIMER_TEXT')}}
          </p>
        </div>
        <button type="button" class="button mt-3 w-100 mb-3" @click="closeModal">
          {{$t('CLOSE')}}
        </button>
        <div class="modal-buffer">

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import config from "../../config";
import statusIcon from '../../assets/images/icon-status.svg';
import plusIcon from '../../assets/images/icon-share.svg';
import startIcon from '../../assets/images/icon-start-date-2.svg'
import endIcon from '../../assets/images/icon-end-date.svg';
import eurIcon from '../../assets/images/icon-euro.svg';
import paymentIcon from '../../assets/images/icon-payment-option.svg';
import Subheader from '../../components/Subheader.vue';
import oneIcon from '../../assets/images/num-1.svg';
import twoIcon from '../../assets/images/num-2.svg';
import threeIcon from '../../assets/images/num-3.svg';
import fourIcon from '../../assets/images/num-4.svg';


export default defineComponent({
  name: "STO",
  components: {
    Subheader,
  },
  data() {
    return {
      htmlBody: document.querySelector('body'),
      showInvestInfoModal: false,
      accessToken: "",
      activeSto: {},
      countDown: {},
      stoStatus: null,
      noSto: false,
      statusIcon: statusIcon,
      plusIcon: plusIcon,
      startIcon: startIcon,
      endIcon: endIcon,
      eurIcon: eurIcon,
      paymentIcon: paymentIcon,
      loggedIn: false,
      loadingSto: true,
      oneIcon: oneIcon,
      twoIcon: twoIcon,
      threeIcon: threeIcon,
      fourIcon: fourIcon,
      unsubscribe: null
    };
  },
  mounted() {
    const element = this.$refs['sto-banner'];
    const top = element.offsetTop - 100;
    window.scrollTo(0, top);
    this.getSto();
    this.$store.watch((state) => {
      console.log(state.accessToken);
      this.accessToken = state.accessToken;
      this.loggedIn = state.loggedIn;
      this.loading = true;
    });
    console.log("sto mounted");
  },
  methods: {
    getSto() {
      fetch(`${config.API}/api/v1/last-sto`, {
        headers: {
          ...config.defaultHeaders,
          Accept: "application/json",
          'Content-Type': "application/json"
        },
      })
      .then((res) => res.json())
      .then((data) => {
        if (!data.data.sto) {
          this.noSto = true;
          this.loading = false;
          return;
        }
        this.activeSto = data.data.sto;
        this.stoStatus = data.data.sto.display_status;
        this.loading = false;
        this.loadingSto = true;
      })
      .catch((error) => {
        console.log(error);
        this.loadingSto = false;
      });
    },
    handleShowInvestInfoModal() {
      this.htmlBody.style.overflow = 'hidden';
      this.showInvestInfoModal = true;
    },
    closeModal() {
      this.htmlBody.style.overflow = 'auto';
      this.showInvestInfoModal = false;
    },
    countdown(date) {
      const thisWrapper = {
        thisProxy: this
      }
      setInterval(function tick() {
        const endDate = new Date(date);
        const currentTime = new Date();
        const remainingTime = endDate.getTime() - currentTime.getTime();
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        thisWrapper.thisProxy.countDown = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
        return tick;
      }(), 1000);
    },
    twoDigits(num) {
      if (num <= 0
        || this.activeSto?.status === 7
        || this.activeSto?.status === 6
        || this.activeSto?.status === 4
        || this.activeSto?.status === 5)
      {
        return '00';
      } else {
        return num?.toString().length > 1 ? num : `0${num}`;
      }
    },
  },
  watch: {
    activeSto: function (data) {
      console.log(data);
      if (status !== 12345) {
        this.countdown(data.end_date);
      }
    },
  },
});
</script>